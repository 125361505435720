<template class="container-fluid h-100">
  <div class="row m-0 p-0">
    <div
      class="col-3 m-0 p-0"
      v-if="isDesktop"
    >
      <sidebar
        :title="leftTitle"
        :smallTitle="leftSmallTitle"
      ></sidebar>
    </div>
    <div class="col-9 p-0 h-100 d-flex flex-column">
      <!-- START QUESTIONNAIRE -->
      <div class="row m-0 p-0">
        <div
          class="col-12 m-0 p-0 w-75 right-scrollable text-center w-75"
          :class="{'bg_darkest': !confirmed, 'w-100': !isDesktop, 'pt-5': !confirmed}"
        >
          <guest_questionnaire
            background="light"
            v-if="confirmed"
            @savedSL="goToHome"
            :personal="true"
            :preferences="true"
            :fromShortlink="true"
            :primaryGuest="primaryGuest"
            :enquiryId="enquiryId"
          ></guest_questionnaire>
          <span
            v-if="!confirmed"
            :class="isDesktop ? 'font2p' : ''"
          >{{message}}</span>
          <div
            class="row m-0 p-0 bg_darkest"
            v-if="!isDesktop"
            :class="{'pt-5': !confirmed}"
          >
            <div class="col m-0 p-3 text-right">
              <img
                src="../assets/images/more_logo.png"
                style="max-width: 40vw;"
              >
            </div>
          </div>
        </div>
      </div>
      <!-- END QUESTIONNAIRE -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'QuestionnaireShortlink',
  components: {
    sidebar: () => import('@/components/SideBar202012.vue'),
    guest_questionnaire: () => import('@/components/GuestQuestionnaire.vue')
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isTablet',
      'serverMedia',
      'user',
      'windowHeight',
      'windowWidth'
    ]),
    backgroundX () {
      return (this.windowWidth / 100) * 14
    },
    isDesktop () {
      return !this.isMobile && !this.isTablet
    }
  },
  async created () {
    this.code = this.$route.params.code
    const { data } = await this.$api.questionnaire.verifyQuestionnaireCode(
      this.code
    )
    if (data.status) {
      this.primaryGuest = data.primaryGuest
      this.enquiryId = data.enquiryId
      this.confirmed = true
    } else {
      switch (data.msg) {
        case 'completed':
          this.goToHome()
          break
        default:
          this.message =
            'Impossible to find questionnaire. Please verify the code [' +
            this.code +
            ']'
          break
      }
    }
  },
  data () {
    return {
      code: null,
      confirmed: false,
      leftSmallTitle: null,
      leftTitle: 'My personal Information',
      message: 'Checking code...',
      primaryGuest: false,
      enquiryId: null,
      topBannerHeightRatio: 1 / 3.4
    }
  },
  methods: {
    ...mapActions(['getProfile']),
    async goToHome () {
      if (this.user) {
        await this.getProfile()
        this.$router.push('/')
      } else {
        this.$router.push('/login')
      }
    },
    topBannerBackgroundImg () {
      return this.serverMedia + 'images/ogp/' + 'bg_2.jpg'
    }
  }
}
</script>
<style scoped>
.profile_pic {
  width: 150px;
  height: 150px;
  object-fit: cover;
  /* border-radius: 50%;
  border: 1px solid #fff;
  background-color: rgba(209, 211, 212, 1); */
}
.right-scrollable {
  position: fixed;
  height: 100%;
  overflow: auto;
}
.sidebar {
  height: 100vh;
  width: 100vw;
  position: absolute;
}
@media (min-width: 992px) {
  .sidebar {
    height: 100vh;
    width: 30vw;
    position: absolute;
  }
  .maincontent {
    height: 100vh;
    width: 70vw;
    background-color: #f5f5f5;
    position: absolute;
    right: 0;
  }
}
</style>
